<template>
  <div class="app">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>代理中心</el-breadcrumb-item>
          <el-breadcrumb-item>代理管理</el-breadcrumb-item>
          <el-breadcrumb-item>运营中心</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="运营区域">
              <el-select
                v-model="where.operationAreaCode"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="(rs, index) in orderStatusList"
                  :label="rs.name"
                  :value="rs.code"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字">
              <el-input
                v-model="where.keyWord"
                placeholder="请输入姓名/手机账号/ID"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="加入时间">
              <el-date-picker
                v-model="date"
                @change="orderDate" @input="immediUpdate"
                type="daterange"
                range-separator="~"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="截止日期"
              >
                <i class="el-icon-date"></i>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                >条件筛选</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-button type="primary" @click="add" icon="el-icon-plus"
                >新增</el-button
              >
            </el-col>
            <el-col :span="12">
              <el-button
                type="primary"
                @click="exportData"
                disabled
                icon="el-icon-tickets"
                >导出数据</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>

      <el-table :data="list" @selection-change="handleSelectionChange">
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          prop="id"
          label="运营中心ID"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="agentName"
          label="运营中心姓名"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机账号"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <!--<el-table-column
          prop="nickName"
          label="会员名称"
          :show-overflow-tooltip="true"
        ></el-table-column>-->
        <el-table-column
          prop="operationArea"
          label="运营区域"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="serviceCount"
          label="区域服务商"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tag type="success">
              <el-link :href="'/Admin/channel/service_providers/serviceproviderslist?area_code='+scope.row.operationAreaCode">{{scope.row.serviceCount}}</el-link>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="bdCount"
          label="区域BD"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tag type="success">
            <el-link :href="'/Admin/channel/extension_workers/extensionworkerslist?area_code='+scope.row.operationAreaCode">{{scope.row.bdCount}}</el-link>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="merchantCount"
          label="区域门店"
          :show-overflow-tooltip="true"
          @click="show('shoplist')"
        >
          <template slot-scope="scope">
            <el-tag type="success">
            <el-link :href="'/Admin/merchant/shop/shoplist?area_code='+scope.row.operationAreaCode">{{scope.row.merchantCount}}</el-link>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="applyDate"
          label="加入时间"
          :show-overflow-tooltip="true"
        ></el-table-column>

        <el-table-column label="使用状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.state == 1">{{
              scope.row.stateText
            }}</el-tag>
            <el-tag type="warning" v-else-if="scope.row.state == 2">{{
              scope.row.stateText
            }}</el-tag>
            <el-tag type="danger" v-else-if="scope.row.state == 0">{{
              scope.row.stateText
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="120px">
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link"
                >操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  ><el-button
                    type="text"
                    class="el-icon-s-shop"
                    size="medium"
                    @click="
                      $router.push({
                        name: 'operationscenterAdd',
                        query: { id: scope.row.id },
                      })
                    "
                    >详情</el-button
                  ></el-dropdown-item
                >
                <el-dropdown-item
                  ><i class="el-icon-setting"></i>下级服务商</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="admin_table_main_pagination">
        <el-pagination
          @current-change="currentChange"
          @size-change="handleSizeChange"
          background
          :total="totalData"
          :page-size="pageSize"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          layout="sizes, prev, pager, next, total, jumper"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    state: Number,
  },
  data() {
    return {
      list: [],
      date: "",
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      orderStatusList: [],
      selectId: "",
      where: {
        pageNum: 0,
        pageSize: 0,
        endTime: "",
        keyWord: "",
        operationArea: "",
        operationAreaCode: "",
        operationCenterId: "",
        // "orderType": "1：根据用户名排序,2：根据手机号排序,3：根据运营区域排序,4：根据服务区域排序",
        serverArea: "",
        serverAreaCode: "",
        serviceId: "",
        startTime: "",
        state: 1,
        userId: "",
      },
    };
  },
  methods: {
    listData() {
      this.where.startTime = this.date?this.date[0]:"";
      this.where.endTime = this.date?this.date[1]:"";
      this.where.pageNum = this.currentPage;
      this.where.pageSize = this.pageSize;
      this.$post(this.$api.operationCenter, this.where).then((res) => {
        this.totalData = res.data.total;
        this.where.pageNum = res.data.pages;
        this.list = res.data.records;
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startTime = obj[0];
        this.where.endTime = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startTime = '';
          this.where.endTime = '';
        }
      });
    },
    exportData() {
      // console.log("exportData");
    },
    add() {
      this.$router.push({ name: "operationscenterAdd" });
    },
    show(name){
      console.log(name);
    },
  },
  created() {
    this.listData();
  },
};
</script>

<style lang="scss" scoped>
// .admin_main_block_right{
//     /*width: 14%;*/
// }
.admin_main_block_right .el-row {
  width: 100%;
}
.admin_main_block_right div {
  float: left;
}
</style>
